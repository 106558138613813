//REACT
import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

//COMPONENTS
import Header from './Components/Header/Header';
import Loader from './Components/Loader/Loader';
import './App.scss';

//ROUTES
const Home = React.lazy(() => import('./Containers/Home/Home'));
const AboutUs = React.lazy(() => import('./Containers/AboutUs/AboutUs'));
const Product = React.lazy(() => import('./Containers/Product/Product'));
const Clients = React.lazy(() => import('./Containers/Clients/Clients'));
const ContactUs = React.lazy(() => import('./Containers/ContactUs/ContactUs'));
const PageNotFound = React.lazy(() => import('./Components/PageNotFound/PageNotFound'));

function App() {

  return (
    <div className="App">
      <React.Suspense
          fallback={<Loader />}
        >
          <Router>

              <Header />

              <Switch>

                {/* HOME */}
                <Route exact path='/' render={() => <Home />} />

                {/* AboutUs */}
                <Route exact path='/aboutus' render={() => <AboutUs />} />

                {/* Product */}
                <Route exact path='/product' render={() => <Product />} />

                {/* Clients */}
                <Route exact path='/clients' render={() => <Clients />} />

                {/* ContactUs */}
                <Route exact path='/contactus' render={() => <ContactUs />} />

                {/* 404 */}
                <Route path='/*' render={() => <PageNotFound />} />

              </Switch>
              
          </Router>
        </React.Suspense>
    </div>
  );
}

export default App;
